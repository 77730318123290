import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import MainWrapper from '../../layouts';
import PaymentFooter from '../../components/Footer/PaymentFooter';
import PaymentHeader from '../../components/Header/PaymentHeader';
import { getObjectFromQueryString } from '../../utils/objectUtils';
import CPPaymentByUUID from '../../components/payments/paymentByUUID';
import FullScreenLoader from '../../components/FullScreenLoader';
import RobotsNoIndex from '../../components/RobotsNoIndex/RobotsNoIndex';

const Payment = (props: PageProps): JSX.Element => {
  const { location } = props;
  const { search, hash } = location;

  const queryObject = getObjectFromQueryString(search);
  const tnxHashStr = hash.replace('#', '');
  const tnx = tnxHashStr || queryObject.tnx_uuid;

  useEffect(() => {
    import('hacktimer');
  }, []);

  return (
    <MainWrapper FooterComponent={<PaymentFooter />} HeaderComponent={<PaymentHeader />}>
      {tnx ? <CPPaymentByUUID paymentUUID={tnx} /> : <FullScreenLoader />}
    </MainWrapper>
  );
};

export default Payment;

export function Head() {
  return <RobotsNoIndex />;
}


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation", "paymentPage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
